//Modales
.modal-content{
    background-color: #E8F8F5;
    //background: linear-gradient(-45deg, #343c44,#575d65 ,#575d65, #557956, #575d65, #575d65, #343c44  );
    border-radius: 20px;
    cursor:pointer;
    width: 100%;
    color:#1099ad;
    border:solid thin  #1099ad// #ff0084;
}

#cajaImagenes{
    height: 300px;
}

.borraImagen{
    display: block !important;
    text-align: center;
    position: absolute;
    z-index: 9999;
    bottom: 0;
    right: 0;
    background-color: red;
    padding: 10px 15px 10px 15px;
    font-size: 18px;
    color: #fff;
    border-radius: 25% 0 0 0;
}

.posicionImagen{
    display: block !important;
    text-align: center;
    position: absolute;
    z-index: 9999;
    top: 0;
    right: 0;
    font-size: 18px;
    width: 3rem;
}

.atributo-contenedor{
    width:15%;
    margin-left: 4%;
    margin-bottom: 3rem !important;
    display: flex;
    flex-direction: column;
    justify-content: end;
    label{
        margin-bottom:5px;
    }
}

.container-sku-child{
    width: 15%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.container-name-child{
    width: 60%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.container-price-child{
    width: 9%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.container-special-price-child{
    width: 9%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.container-stock-child{
    width: 9%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.container-sku-parent{
    width: 15%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.container-name-parent{
    width: 60%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.container-price-parent{
    width: 9%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.container-special-price-parent{
    width: 9%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.container-stock-parent{
    width: 9%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.modal-header-import{
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
    border-top-left-radius: var(--bs-modal-inner-border-radius);
    border-top-right-radius: var(--bs-modal-inner-border-radius);
}